import React, {FC, useEffect, useState} from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
import {service_url, mailto_list} from "./config";
// import { Button } from "react-bootstrap";
import axios from "axios";
import "./css/tachyons.min.css"
import { useNavigate } from "react-router-dom";

import dayjs from "dayjs";

import { useMemo } from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/Preview';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Swal from "sweetalert2";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Container } from "@mui/material";
import { edit_expiration_threshold } from './config'
const deleteConfirmation = (case_id, update_table)=>{
    // console.log(case_id)
    Swal.fire({
        icon:"warning",
        title: "Are you sure you want to delete this case entry?",
        html: "<div class= 'w-100 pa2 fl'>\
           <input \
           class= 'br3 w-80 pa2'\
           id='swal-case-id'\
           placeholder='Enter Case ID to Confirm'/> \
        </div>",
        confirmButtonText:"Delete",
        confirmButtonColor:"#C41E3A",
        cancelButtonColor:"Grey",
        cancelButtonText:"Cancel",
        allowEscapeKey:false,
        allowOutsideClick:false,
        allowEnterKey: false,
        showCancelButton: true,
        didOpen: ()=> {
            Swal.getConfirmButton().disabled = true
            document.getElementById('swal-case-id').addEventListener('keyup', function (e) {
                if (e.target.value === case_id) {
                    Swal.getConfirmButton().disabled = false;
                }
                else{
                    Swal.getConfirmButton().disabled = true;
                }
                
            })
        },
        preConfirm: () => {
            
        }
    }).then((result)=>{
        if(result.isConfirmed){
            update_table(case_id)
        }
    })

}
const ReviewComp = (props) =>{
    let navigate = useNavigate();
    // console.log(props.create_date, typeof(props.create_date))
    // console.log(props)
    let review_delete = (props.super_user === false && props.duration_days <= edit_expiration_threshold && props.type === 'submitted') ||
        ((props.super_user === false && props.type === 'unsubmitted'))
    return( < span >
                        
                        <Tooltip title="Review">
                            <Button sx={{mr:1, px:0, minWidth:0, minHeight:0}}
                                onClick={() => {
                                // console.log("Preview")
                                navigate('/' + "single_case?surveyUUID=" + props.id + "&type=" + props.type)
                            }}>
                            <PreviewIcon sx={{ fontSize: 27, color: '#93C572' }} />
                        </Button>
                        </Tooltip>
                        
        { review_delete &&
                        <>
                        
                        <Tooltip title="Edit">
                        <Button sx={{ mx: 1, px: 0, minWidth: 0, minHeight: 0 }}
                            onClick={() => {
                                // console.log("Edit")
                                navigate('/' + "edit_single_case?surveyUUID=" + props.id + "&type=" + props.type)
                            }}>
                            <EditIcon sx={{ fontSize: 27, color: '#0047AB' }} />
                        </Button>
                        </Tooltip>

                        <Tooltip title="Delete">
                        <Button sx={{ mx: 1, px: 0, minWidth: 0, minHeight: 0 }}
                            onClick={() => {
                                deleteConfirmation(props.id, props.delete_case)
                            }}>
                            <DeleteForeverIcon sx={{ fontSize: 27, color: '#C41E3A' }} />
                        </Button>
                        </Tooltip>
                        </>
                        }
                                
                    </span >)


}



const ReviewCases= props => {

    
    //data and fetching state
    
    const [data, setData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    // const [isRefetching, setIsRefetching] = useState(false);
    // const [rowCount, setRowCount] = useState(0);

    const delete_case = (case_id)=> {
        let dataString = {'case_id':case_id}
        var config = {
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        };
          
        axios.post(service_url + "/surveysession/deleteUserSurvey", dataString, config)
        .then((response) => {
            setData((data) =>
                data.filter(item => item.id !== case_id)
            );
        }, (error) => {
            Swal.fire({
                icon: "error",
                title: "Connection Error (Notify Admin)",
                allowOutsideClick: false,
                html: "Unable to delete the case. Please <a href=" + { mailto_list } + " target='_blank'>email support</a> \
                            and send a screenshot of this error message<br><br>\
                            <div class='outline>"+ error + "</div>"
                }).then((response) => {}, (error)=>{
                    console.log("Deletion ERROR:", error);
                })

        });
    }

    const [search_id, setSearchID] = useState(sessionStorage.getItem('user_name'));
    var userName= search_id.replaceAll('"', "");
    

    const getAllSurveys = (name) => {
        var offset = new Date().getTimezoneOffset();
        var dataString = {
            "UserName": name, 
            "offset": offset,
            "reviewFlag": props.type
        };
        var config = {
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        };
    
        axios.post(service_url + "/surveysession/getUserSurveys", dataString, config)
            .then((response) => {
                setData(response.data);
                // console.log(response.data)
                setIsLoading(false);
                // console.log(response.data)
            }, (error) => {
                console.log(error);
                setIsError(true)
            });
    }
    const columns = useMemo(
        ()=>[
            {
                
                header: 'Actions',
                minSize: 100,
                Cell: ({cellValue, row}) => 
                <ReviewComp 
                    type={props.type} 
                    id= {row.original.id} 
                    delete_case={delete_case}
                    create_date = {row.original.create_dt}
                    super_user={row.original.request_user_role===0}
                    duration_days = {row.original.create_duration_days}
                />
            },
            {
                accessorKey: 'id', //access nested data with dot notation
                header: 'Case ID',
                size: 50,
                options:{
                    muiTableBodyCellProps:{
                        sx:{
                            whiteSpace: 'normal',
                        }
                    }
                }
            },
            {
                accessorKey: 'user',
                header: 'Latest Editor',
                size: 50,
            },
            {
                accessorKey: 'initial_user',
                header: 'Original Submittor',
                size: 50
                
            },
            {
                id: 'create_dt',
                accessorFn: (row) => dayjs(row.create_dt, 'MM/DD/YYYY, HH:mm:ss'),
                header: 'Orig Submission Timestamp',
                filterVariant: 'date',
                filterFn: 'lessThan',
                sortingFn: 'datetime',
                size: 300,
                Cell: ({ cell }) => cell.getValue()?dayjs(cell.getValue()).format('MM/DD/YYYY, HH:mm:ss'):""
                
            },
            {
                id: 'update_dt',
                accessorFn: (row) => dayjs(row.update_dt, 'MM/DD/YYYY, HH:mm:ss'),
                Cell: ({ cell }) => dayjs(cell.getValue()).format('MM/DD/YYYY, HH:mm:ss'),
                header: 'Last Edit Timestamp',
                filterVariant: 'date',
                filterFn: 'lessThan',
                sortingFn: 'datetime',
                size: 50
            },
            {
                accessorKey: 'event_type',
                header: 'Events',
                size: 50
            }
            
        ],
        [],
    );
    

    useEffect(() => {
        // console.log(submitFlag)
        
            if (!data.length) {
                setIsLoading(true);
            } else {
                // setIsRefetching(true);
            }
            getAllSurveys(userName);
            // console.log(data)
        
        return () => {}; 

    },[]);


        const table = useMaterialReactTable({
            columns,
            data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
            globalFilterFn: 'contains',
            muiTablePaperProps: {
                // elevation: 0, //change the mui box shadow
                //customize paper styles
                sx: {
                    mx:2,
                    my:2,
                    width:"100%",
                    borderRadius: '2',
                    border: '1px solid #e0e0e0',
                },
            },
            state:{
                isLoading,
                isError,
                showAlertBanner: isError,
            },
            initialState: {
                sorting: [
                    {
                        id: 'update_dt', //sort by update date by default on page load
                        desc: true,
                    },
                ]
            }
        });    

    return (<>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Container maxWidth="xl" sx={{mt:10}}>
        <MaterialReactTable table={table} />
    </Container>
    </LocalizationProvider>
    </>)
    // return(<></>)
};

export default ReviewCases;
