
export const edit_expiration_threshold = 60
export const mfa_guide_url = "https://wus-documents.s3.amazonaws.com/WUS_MFA_Guide.pdf"
export const service_url = "https://api.wusreport.com";
export const dashboard_url = "https://analytics.wusreport.com"
export const email_service = "https://admin-api.wusreport.com";
export const user_manual_url = "https://wus-documents.s3.us-east-1.amazonaws.com/WUSCaseReportingUserGuideVer2.pdf"
export const data_dictionary_url = "https://www.dropbox.com/scl/fi/58dmutub3635fsjey4cz0/WUS_Dictionary-ver1.2.xlsx?rlkey=y64v1jzmjhscy2i9ff30plays&dl=0"
export const version_num = "Ver. 2.1"
export const mailto_list = "'mailto:grovers1@chop.edu,shil2@chop.edu,wakeupsafe@gmail.com,tsuif@chop.edu'"
export const request_config = {
    headers: {
        'Access-Control-Allow-Origin': '*'
    }
};
