import React, { FC, useEffect, useState, useMemo } from "react";
import "./css/tachyons.min.css"
import {TextField, Container, Grid, Button } from "@mui/material";
import { Input } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import dayjs from "dayjs";
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import axios from "axios";
import { service_url, request_config } from "./config";



const Denominator = ()=>{
    const [data, setData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [denval, setDenVal] = useState(null)
    const [entryDate, setEntryDate] = useState(dayjs())
    const [refetch, setRefetch] = useState(0)
    useEffect(()=>{
        if (!data.length) {
            setIsLoading(true);
        } else {
            // console.log("UE:",data)
            // setIsRefetching(true);
        }
        axios.post(service_url+'/get_denominator_data', {'site':sessionStorage.getItem('site_id')},request_config)
        .then((response)=>{
            // let output = response.data
            // console.log(output)
            setData(response.data['data']);
            setIsLoading(false);
            
        },(error)=>{
            console.log(error);
            setIsError(true)

        })
        return () => { };
    },[refetch])

    const columns = useMemo(
        () => [
            
            {
                accessorKey: 'month', //access nested data with dot notation
                header: 'Month',
                accessorFn: (row) => dayjs(row.month, 'YYYY-MM'),
                filterVariant: 'date',
                filterFn: 'lessThan',
                sortingFn: 'datetime',
                Cell: ({ cell }) => cell.getValue() ? dayjs(cell.getValue()).format('MMM-YYYY') : "",
                size: 25,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                }
            },
            {
                accessorKey: 'counts',
                header: 'Case Count',
                Cell: ({ cell }) => cell.getValue(),
                size: 25,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                }
            },

        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
        globalFilterFn: 'contains',
        
        muiTablePaperProps: {
            // elevation: 0, //change the mui box shadow
            //customize paper styles
            sx: {
                mx: 2,
                my: 2,
                width: "100%",
                borderRadius: '2',
                border: '1px solid #e0e0e0',
            },
        },
        state: {
            isLoading,
            isError,
            showAlertBanner: isError,
        },
        initialState: { 
            density: 'compact',
            sorting: [
                {
                    id: 'month', //sort by update date by default on page load
                    desc: true,
                },
            ]
        },
    });

    
    return <>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Container maxWidth="md" sx={{ mt: 16 }}>
            <Grid container direction="row"
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                <Grid item xs={4}>
                    <DatePicker
                        views={['year', 'month']}
                        label="Year and Month"
                        minDate={dayjs(new Date('2023-04-02'))}
                        maxDate={dayjs()}
                        value={entryDate}
                        onChange={setEntryDate}
                        renderInput={(params) => <TextField {...params} helperText={null} />}
                        sx={{ maxWidth: '80%'}}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Input
                        
                        slotProps={{ input: { type: 'number' } }}
                        // value={denval}
                        onChange={(e) => { setDenVal(e.target.value) }}
                    />
                </Grid>
            </Grid>
            <Button
            variant = 'contained'
            sx={{mt:4}}
            onClick ={()=>{
                axios.post(service_url+'/set_denominator_data',{"site":sessionStorage.getItem('site_id'),"counts":denval, "month":entryDate.format('YYYY-MM')}, request_config)
                .then((response)=>{
                    setData([])
                    setRefetch(refetch+1)
                },(error)=>{
                    console.log("INSERTION ERROR",error)
                })
            }}
            >
                Submit
            </Button>
            </Container>

            <Container maxWidth="md" sx={{ mt: 2 }}>
                <MaterialReactTable table={table} />
            </Container>

        </LocalizationProvider>

    </>
}

export default Denominator; 